import React from "react"
import LayoutInner from "./layoutInner"
import {TextField, Button, withStyles, Grid} from "@material-ui/core";
import {Link} from "gatsby"
import {joinPaths, truncatePleasantly} from '../utils/index'
import * as PATHS from '../utils/paths'

const newsletterFormStyles = theme => ({
    root: {},
    guineaPigImage: {
        maxHeight: 200,
        [theme.breakpoints.down('xs')]: {
            maxHeight: 128,
            marginBottom: 8
        },
    },
    header: {
        fontSize: 42,
        lineHeight: "48px",
        marginBottom: 0
    },
    explainer: {
        fontSize: 22,
        fontWeight: 400,
        lineHeight: "32px",
        marginBottom: 8
    },
    explainerSmall: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "24px",
        marginBottom: 0
    },
    submitButton: {
        backgroundColor: theme.palette.success.main,
        borderColor: theme.palette.success.dark,
        color: theme.palette.success.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
        },
        '&:focus': {},
    },
    textField: {
        border: '1px solid #e2e2e1',
        overflow: 'hidden',
        borderRadius: 4,
        background: '#fff',
        '& input': {
            background: "#ffffff"
        },
        // transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:hover': {
            background: '#fff',
        },
        '&$focused': {
            background: '#fff',
            borderColor: theme.palette.primary.main,
        }
    }

});

const _NewsletterForm = ({classes}) => {
    return (
        <form
            action="https://brboinc.us17.list-manage.com/subscribe/post?u=fc4fcdd6e6bcfa0f1a9466c1c&amp;id=61c1f58d5e"
            method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
            className="validate" target="_blank" noValidate>

            <Grid container spacing={4} className={classes.root} spacing={4}>
                <Grid item xs={12} sm={4}  className={classes.leftColumn}>
                    <img className={classes.guineaPigImage}
                         src={joinPaths([PATHS.CDN, "images/guinea-pig.png"])}
                         alt={"a curious guinea pig"}/>
                </Grid>
                <Grid item xs={12} sm={8} className={classes.rightColumn}>
                    <Grid container alignContent={"flex-start"} direction={"column"} spacing={2}>

                        <Grid item>
                            <Grid container spacing={2} alignItems={'baseline'}>
                                {/*<Grid item style={{paddingBottom: 0}}>*/}

                                {/*</Grid>*/}
                                <Grid item>
                                    <h2 className={classes.header}>Would you like to be a guinea pig?</h2>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>

                            <p className={classes.explainer}>Join 3iap’s mailing list for early access to the latest dataviz research, writing, and experiments.</p>
                            <p className={classes.explainerSmall}>No guinea pigs (or humans) have been harmed in the course of 3iap’s
                                research, writing, or experiments.</p>
                        </Grid>

                        <Grid item>
                            <TextField id="mce-EMAIL" name="EMAIL" label="Email Address"
                                       // className={classes.textField}
                                       InputProps={{ classes: {root: classes.textField}, disableUnderline: true }}
                                       variant="filled"
                                       fullWidth/>
                        </Grid>

                        <div style={{position: "absolute", left: "-5000px", height: 0, padding: 0, margin: 0}} aria-hidden="true">
                            <input type="text"
                                   name="b_fc4fcdd6e6bcfa0f1a9466c1c_61c1f58d5e"
                                   tabIndex="-1"
                                   defaultValue=""/>
                        </div>
                        <Grid item>
                            <Button className={classes.submitButton} type="submit" value="Subscribe" name="subscribe"
                                    id="mc-embedded-subscribe" variant={"contained"}>Submit</Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </form>


    )
}

const NewsletterForm = withStyles(newsletterFormStyles)(_NewsletterForm)


const footerStyles = theme => ({
    root: {
        color: "#fff"
    }
});
const _Footer = ({classes, hideNewsletterForm=false}) => {


    return (

        <div className={classes.root}>
            <div style={{
                paddingTop: 40,
                paddingBottom: 48,
                marginTop: 48,
                backgroundColor: "#6893B4"
            }}>

                {false==hideNewsletterForm && (
                  <LayoutInner>
                      <NewsletterForm/>
                  </LayoutInner>
                )}

            </div>


            <footer style={{
                backgroundColor: "#3C4660",
                color: "#ffffff",
                // paddingLeft: 16,
                // paddingRight: 16,
                paddingTop: 16,
                paddingBottom: 16,
                // textAlign: "center"
            }}>
                <LayoutInner>
                    <br/><br/>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            3iap (<i>3 is a pattern</i>) is a data, design and analytics consulting practice,
                            specializing in data strategy, visualization, product design, and custom analysis.</Grid>
                        <Grid item xs={12} sm={6}>
                            {/*Services:*/}
                            <ul style={{marginTop: 0, paddingLeft: 16}}>
                                <li>
                                    <Link style={{color: "#ffffff"}} to={"/services/#data-visualization"}>Data visualization design</Link>
                                </li>
                                <li>
                                    <Link style={{color: "#ffffff"}} to={"/services/#data-product-design-development"}>Data product design and development</Link>
                                </li>
                                <li>
                                    <Link style={{color: "#ffffff"}} to={"/services/#research-discovery"}>Data research and analysis</Link>
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                    <br/><br/>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>© {new Date().getFullYear()} 3iap, Inc.</Grid>
                        <Grid item xs={12} sm={4}>♥ New York, NY</Grid>
                        <Grid item xs={12} sm={4}><a style={{color: "#fff"}} href="mailto:hi@3isapattern.com">hi@3isapattern.com</a></Grid>
                    </Grid>
                </LayoutInner>

            </footer>
        </div>


    )
}

const Footer = withStyles(footerStyles())(_Footer)
export default Footer
